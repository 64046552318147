import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import axios from 'axios';

import DefaultLayout from '../components/layout/Default';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

function LoginPage() {
  const [error, setError] = useState(null);

  const initialValues = () => ({
    username: '',
    password: '',
  });

  return (
    <Formik
      initialValues={initialValues()}
      onSubmit={(values) => {
        axios
          .post('/api/login', {
            username: values.username,
            password: values.password,
          })
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
            } else {
              // eslint-disable-next-line no-undef
              window.location.href = response.data.data.launchURL;
            }
          })
          .catch((error) => {
            setError(error.message);
          });
      }}
      enableReinitialize
    >
      {() => (
        <DefaultLayout>
          <Header />
          <div className="content">
            <Container>
              <Form className="form-login">
                {error && error.length && (
                  <Alert variant="danger">{error}</Alert>
                )}
                <fieldset className="form-fieldset">
                  <Row>
                    <Col className="col-12-x-6-x">
                      <Form.Group controlId="username-form-group">
                        <Form.Label>Username</Form.Label>
                        <Field name="username" className="form-control" />
                      </Form.Group>
                    </Col>
                    <Col className="col-12-x-6-x">
                      <Form.Group controlId="password-form-group">
                        <Form.Label>Password</Form.Label>
                        <Field
                          name="password"
                          type="password"
                          className="form-control"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </fieldset>
              </Form>
            </Container>
          </div>
          <Footer />
        </DefaultLayout>
      )}
    </Formik>
  );
}

export default LoginPage;
